import { render, staticRenderFns } from "./creatCoupon.vue?vue&type=template&id=945b1358&scoped=true&"
import script from "./creatCoupon.vue?vue&type=script&lang=js&"
export * from "./creatCoupon.vue?vue&type=script&lang=js&"
import style0 from "./creatCoupon.vue?vue&type=style&index=0&id=945b1358&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "945b1358",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\GranVida\\source\\admin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('945b1358')) {
      api.createRecord('945b1358', component.options)
    } else {
      api.reload('945b1358', component.options)
    }
    module.hot.accept("./creatCoupon.vue?vue&type=template&id=945b1358&scoped=true&", function () {
      api.rerender('945b1358', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/marketing/coupon/list/creatCoupon.vue"
export default component.exports