"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _order = require("@/api/order");

var _vueBarcode = _interopRequireDefault(require("@xkeshi/vue-barcode"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    barcode: _vueBarcode.default
  },
  data: function data() {
    return {
      orderId: '',
      orderData: {},
      orderDatalist: {
        flowingCode: ''
      },
      orderInfo: [],
      barcode_option: {
        displayValue: true,
        //是否默认显示条形码数据
        background: '#fff',
        //条形码背景颜色
        font: "monospace",
        textAlign: "center",
        height: '50px',
        fontSize: '15',
        lineColor: "#000000"
      }
    };
  },
  mounted: function mounted() {
    this.getOrderData();
  },
  methods: {
    getOrderData: function getOrderData() {
      this.orderData = this.$route.query.orderData; // this.orderId = this.orderData.substr(-23);

      this.orderId = this.orderData.orderId.substr(-16); //this.orderId = this.orderData.orderId.slice(5)

      console.log(this.orderData);
      this.getDetail(this.orderData.orderId);
    },
    getDetail: function getDetail(id) {
      var _this = this;

      (0, _order.orderDetailApi)({
        orderNo: id
      }).then(function (res) {
        _this.orderDatalist = res;
        _this.orderDatalist.flowingCode = res.flowingCode.substr(res.flowingCode.length - 3);
        console.log(_this.orderDatalist.flowingCode);
        _this.orderInfo = res.orderInfo;
        console.log("详情信息" + JSON.stringify(_this.orderDatalist));
        console.log(_this.orderInfo);
      }).catch(function () {
        _this.orderDatalist = null;
      });
    },
    prints: function prints() {
      var prints = document.getElementById('printTemplate');
      prints.title = "订单";
    }
  }
};
exports.default = _default;