//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { productLstApi, productDeleteApi, categoryApi, putOnShellApi, offShellApi, puOnShlefApi, offShlefApi, onekeySaleApi, onekeyOffSaleApi, onekeyEditApi, productHeadersApi, productExportApi, restoreApi, productExcelApi, putOnSaleApi, offSaleApi } from '@/api/store';
import { getToken } from '@/utils/auth';
import taoBao from './taoBao';
import { checkPermi } from "@/utils/permission"; // 权限判断函数

import { errorLog } from '@/settings';
export default {
  name: 'ProductList',
  components: {
    taoBao: taoBao
  },
  data: function data() {
    return {
      props: {
        children: 'child',
        label: 'name',
        value: 'id',
        emitPath: false
      },
      // roterPre: roterPre,
      headeNum: [],
      listLoading: true,
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: 20,
        cateId: '',
        keywords: '',
        type: '1',
        priceMin: '',
        priceMax: '',
        stockMin: '',
        stockMax: ''
      },
      categoryList: [],
      merCateList: [],
      objectUrl: process.env.VUE_APP_BASE_API,
      dialogVisible: false,
      putOnStatu: true,
      offStatu: true,
      multipleSelection: []
    };
  },
  mounted: function mounted() {
    this.goodHeade();
    this.getList();
    this.getCategorySelect();
    this.checkedCities = this.$cache.local.has('goods_stroge') ? this.$cache.local.getJSON('goods_stroge') : this.checkedCities;
  },
  methods: {
    checkPermi: checkPermi,
    handleRestore: function handleRestore(id) {
      var _this = this;

      this.$modalSure("恢复商品").then(function () {
        restoreApi(id).then(function (res) {
          _this.$message.success('操作成功');

          _this.goodHeade();

          _this.getList();
        });
      });
    },
    seachList: function seachList() {
      this.tableFrom.page = 1;
      console.log(this.tableFrom);
      this.getList();
    },
    handleClose: function handleClose() {
      this.dialogVisible = false;
    },
    handleCloseMod: function handleCloseMod(item) {
      this.dialogVisible = item;
      this.goodHeade();
      this.getList();
    },
    // 复制
    onCopy: function onCopy() {
      this.dialogVisible = true;
    },
    // 导出
    exports: function exports() {
      productExcelApi({
        cateId: this.tableFrom.cateId,
        keywords: this.tableFrom.keywords,
        type: this.tableFrom.type
      }).then(function (res) {
        window.location.href = res.fileName;
      });
    },
    // 获取商品表单头数量
    goodHeade: function goodHeade() {
      var _this2 = this;

      productHeadersApi().then(function (res) {
        _this2.headeNum = res;
      }).catch(function (res) {
        _this2.$message.error(res.message);
      });
    },
    // 商户分类；
    getCategorySelect: function getCategorySelect() {
      var _this3 = this;

      categoryApi({
        status: -1,
        type: 1
      }).then(function (res) {
        _this3.merCateList = res;
      }).catch(function (res) {
        _this3.$message.error(res.message);
      });
    },
    // 列表
    getList: function getList() {
      var _this4 = this;

      this.listLoading = true;
      productLstApi(this.tableFrom).then(function (res) {
        _this4.tableData.data = res.list;
        _this4.tableData.total = res.total;
        _this4.listLoading = false;
      }).catch(function (res) {
        _this4.listLoading = false;

        _this4.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    // 删除
    handleDelete: function handleDelete(id, type) {
      var _this5 = this;

      this.$modalSure("\u5220\u9664 id \u4E3A ".concat(id, " \u7684\u5546\u54C1")).then(function () {
        var deleteFlag = type == 5 ? 'delete' : 'recycle';
        productDeleteApi(id, deleteFlag).then(function () {
          _this5.$message.success('删除成功');

          _this5.getList();

          _this5.goodHeade();
        });
      });
    },
    onchangeIsShow: function onchangeIsShow(row) {
      var _this6 = this;

      row.isShow ? putOnShellApi(row.id).then(function () {
        _this6.$message.success('上架成功');

        _this6.getList();

        _this6.goodHeade();
      }).catch(function () {
        row.isShow = !row.isShow;
      }) : offShellApi(row.id).then(function () {
        _this6.$message.success('下架成功');

        _this6.getList();

        _this6.goodHeade();
      }).catch(function () {
        row.isShow = !row.isShow;
      });
    },
    //可售与不可售状态切换
    onchangeIsCansale: function onchangeIsCansale(row) {
      var _this7 = this;

      console.log(row.isCansale);
      row.isCansale ? putOnSaleApi(row.id).then(function () {
        _this7.$message.success('可售成功');

        _this7.getList();

        console.log('可售' + JSON.stringify(_this7.tableData.data));

        _this7.goodHeade();
      }).catch(function () {
        row.isCansale = !row.isCansale;
      }) : offSaleApi(row.id).then(function () {
        _this7.$message.success('不可售成功');

        _this7.getList();

        console.log('不可售' + JSON.stringify(_this7.tableData.data));

        _this7.goodHeade();
      }).catch(function () {
        row.isCansale = !row.isCansale;
      });
    },
    //全部选中
    handleSelectionChange: function handleSelectionChange(val) {
      console.log('勾选');
      console.log('val' + JSON.stringify(val));
      this.multipleSelection = val;
      this.putOnStatu = val.length == 0;
      this.offStatu = val.length == 0;
    },
    //一键上架
    putOnShelf: function putOnShelf(id) {
      var _this8 = this;

      var ids = [];

      if (id) {
        ids.push(id);
      } else {
        this.multipleSelection.forEach(function (row) {
          console.log('上架状态' + row.isShow);

          if (row.isShow) {
            _this8.$message.error("选中商品包含已上架");
          } else {
            ids.push(row.id);
          }
        });
      }

      puOnShlefApi(ids).then(function () {
        _this8.$message.success('上架成功');

        _this8.getList();

        _this8.goodHeade();
      }, function (error) {
        console.log(errorLog);
      }).catch(function () {
        console.log('cuowu');
      });
    },
    //一键下架
    offShelf: function offShelf(id) {
      var _this9 = this;

      var ids = [];

      if (id) {
        ids.push(id);
      } else {
        this.multipleSelection.forEach(function (row) {
          if (!row.isShow) {
            _this9.$message.error("选中商品包含已下架");
          } else {
            ids.push(row.id);
          }
        });
      }

      offShlefApi(ids).then(function () {
        _this9.$message.success('下架成功');

        _this9.getList();

        _this9.goodHeade();
      }, function (error) {
        console.log(errorLog);
      }).catch(function () {
        console.log('cuowu');
      });
    },
    //一键出售
    onekeySale: function onekeySale(id) {
      var _this10 = this;

      var ids = [];

      if (id) {
        ids.push(id);
      } else {
        this.multipleSelection.forEach(function (row) {
          if (row.isCansale) {
            _this10.$message.error("选中商品已可售");
          } else {
            ids.push(row.id);
          }
        });
      }

      console.log(ids);
      onekeySaleApi(ids).then(function () {
        _this10.$message.success('可售成功');

        _this10.getList();

        _this10.goodHeade();
      }, function (error) {
        console.log(errorLog);
      }).catch(function () {
        console.log('cuowu');
      });
    },
    //一键不可售
    onekeyOffSale: function onekeyOffSale(id) {
      var _this11 = this;

      var ids = [];

      if (id) {
        ids.push(id);
      } else {
        this.multipleSelection.forEach(function (row) {
          if (!row.isCansale) {
            _this11.$message.error("选中商品包含不可售");
          } else {
            ids.push(row.id);
          }
        });
      }

      console.log(ids);
      onekeyOffSaleApi(ids).then(function () {
        _this11.$message.success('不可售成功');

        _this11.getList();

        _this11.goodHeade();
      }, function (error) {
        console.log(errorLog);
      }).catch(function () {
        console.log('cuowu');
      });
    },
    //一键编辑
    onekeyEdit: function onekeyEdit(id) {
      var _this12 = this;

      console.log("一键编辑");
      var ids = [];

      if (id) {
        ids.push(id);
      } else {
        this.multipleSelection.forEach(function (row) {
          ids.push(row.id);
        });
      }

      console.log(ids);
      onekeyEditApi(ids).then(function () {
        _this12.$message.success('成功');

        _this12.getList();

        _this12.goodHeade();
      }, function (error) {
        console.log(errorLog);
      }).catch(function () {
        console.log('cuowu');
      });
    }
  }
};