import { render, staticRenderFns } from "./closing.vue?vue&type=template&id=64cecf15&scoped=true&"
import script from "./closing.vue?vue&type=script&lang=js&"
export * from "./closing.vue?vue&type=script&lang=js&"
import style0 from "./closing.vue?vue&type=style&index=0&id=64cecf15&lang=scss&scoped=true&media=print&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64cecf15",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\GranVida\\source\\admin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('64cecf15')) {
      api.createRecord('64cecf15', component.options)
    } else {
      api.reload('64cecf15', component.options)
    }
    module.hot.accept("./closing.vue?vue&type=template&id=64cecf15&scoped=true&", function () {
      api.rerender('64cecf15', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/printTicket/closing.vue"
export default component.exports