"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _financial = require("@/api/financial");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'AccountsCapital',
  data: function data() {
    return {
      timeVal: [],
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true,
      tableFrom: {
        type: '',
        page: 1,
        limit: 20
      },
      userTableFrom: {
        page: 1,
        limit: 10,
        dateLimit: ''
      },
      fromList: this.$constants.fromList,
      options: [],
      typeOptions: [{
        value: 1,
        label: '订单返佣'
      }, {
        value: 2,
        label: '申请提现'
      }, {
        value: 3,
        label: '提现失败'
      }, {
        value: 4,
        label: '提现成功'
      }, {
        value: 5,
        label: '佣金转余额'
      }]
    };
  },
  mounted: function mounted() {
    // this.getTypes()
    this.getList();
  },
  methods: {
    // 列表
    getList: function getList(num) {
      var _this = this;

      this.listLoading = true;
      this.tableFrom.page = num ? num : this.tableFrom.page;
      (0, _financial.brokerageListApi)(this.tableFrom).then(function (res) {
        _this.tableData.data = res.list;
        _this.tableData.total = res.total;
        _this.listLoading = false;
      }).catch(function (res) {
        _this.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    getTypes: function getTypes() {
      var _this2 = this;

      billTypeApi().then(function (res) {
        _this2.options = res.data;
        localStorage.setItem('CashKey', JSON.stringify(res.data));
      }).catch(function (res) {
        _this2.$message.error(res.message);
      });
    }
  }
};
exports.default = _default;