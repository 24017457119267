var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-button",
        {
          directives: [
            {
              name: "print",
              rawName: "v-print",
              value: "#printTemplate",
              expression: "'#printTemplate'",
            },
          ],
          attrs: { id: "printStart", type: "success" },
          on: { click: _vm.prints },
        },
        [_vm._v("打印")]
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { id: "printTemplate" } },
        [
          _c("div", { staticClass: "print-item f-s tl bold" }, [
            _vm._v("结账联"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "print-item f-m tc" }, [_vm._v("Granvida")]),
          _vm._v(" "),
          _c("div", { staticClass: "print-item f-s tl" }, [
            _vm._v("门店名称：Granvida生活超市"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "print-item f-s tl" }, [
            _vm._v("预计送达：2022-05-30"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "print-item f-s tl" }, [
            _vm._v("下单时间：" + _vm._s(_vm.orderData.createTime)),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "print-item f-s tl" }, [
            _vm._v("\n      订单编号：" + _vm._s(_vm.orderId) + "\n    "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "print-item f-m tl bold" }, [
            _vm._v("备注：" + _vm._s(_vm.orderData.remark)),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "dashed" }),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "dashed" }),
          _vm._v(" "),
          _vm._l(_vm.orderData.orderInfo, function (item, index) {
            return _c("div", { key: index }, [
              _c("div", { staticClass: "print-item f-m tl bold" }, [
                _vm._v("\n        " + _vm._s(index + 1)),
                _c("span", { staticClass: "space" }, [_vm._v(" ")]),
                _vm._v(_vm._s(item.info.productName)),
                _c("span", { staticClass: "space" }, [_vm._v(" ")]),
                _vm._v(_vm._s(item.info.sku) + "\n      "),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "print-item f-m tl" }, [
                _c("span", { staticClass: "space" }, [_vm._v(" ")]),
                _vm._v("x" + _vm._s(item.info.payNum)),
                _c("span", { staticClass: "space" }, [_vm._v(" ")]),
                _c("span", { staticClass: "space" }, [_vm._v(" ")]),
                _c("span", { staticClass: "space" }, [_vm._v(" ")]),
                _c("span", { staticClass: "space" }, [_vm._v(" ")]),
                _c("span", { staticClass: "space" }, [_vm._v(" ")]),
                _vm._v(_vm._s(item.info.price)),
                _c("span", { staticClass: "space" }, [_vm._v(" ")]),
                _c("span", { staticClass: "space" }, [_vm._v(" ")]),
                _c("span", { staticClass: "space" }, [_vm._v(" ")]),
                _c("span", { staticClass: "space" }, [_vm._v(" ")]),
                _vm._v(_vm._s(item.info.payNum * item.info.price) + "\n      "),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "print-item f-s tl" }, [
                _vm._v("店内码：" + _vm._s(item.info.plucode)),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "print-item f-s tl" }, [
                _vm._v("条码：" + _vm._s(item.info.barCode)),
              ]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("barcode", {
                    staticStyle: { width: "190px", height: "70px" },
                    attrs: {
                      value: item.info.plucode,
                      options: _vm.barcode_option,
                    },
                  }),
                ],
                1
              ),
            ])
          }),
          _vm._v(" "),
          _c("div", { staticClass: "dashed" }),
          _vm._v(" "),
          _c("div", { staticClass: "print-item f-s tl" }, [
            _vm._v("\n      商品金额:" + _vm._s(_vm.orderDatalist.totalPrice)),
            _c("span", { staticClass: "space" }, [_vm._v(" ")]),
            _c("span", { staticClass: "space" }, [_vm._v(" ")]),
            _vm._v(
              "商品优惠:" + _vm._s(_vm.orderDatalist.couponPrice) + "\n    "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "print-item f-s tl" }, [
            _vm._v("配送金额:" + _vm._s(_vm.orderDatalist.payPostage)),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "dashed" }),
          _vm._v(" "),
          _c("div", { staticClass: "print-item f-m tl bold" }, [
            _vm._v("\n      总件数：" + _vm._s(_vm.orderDatalist.totalNum)),
            _c("span", { staticClass: "space" }, [_vm._v(" ")]),
            _c("span", { staticClass: "space" }, [_vm._v(" ")]),
            _c("span", { staticClass: "space" }, [_vm._v(" ")]),
            _c("span", { staticClass: "space" }, [_vm._v(" ")]),
            _c("span", { staticClass: "space" }, [_vm._v(" ")]),
            _c("span", { staticClass: "space" }, [_vm._v(" ")]),
            _vm._v("实付：" + _vm._s(_vm.orderData.payPrice) + "\n    "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "dashed" }),
          _vm._v(" "),
          _c("p", { staticClass: "br" }),
          _vm._v(" "),
          _c("div", { staticClass: "print-item f-m tl bold" }, [
            _vm._v("收货人：" + _vm._s(_vm.orderData.realName)),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "print-item f-m tl bold" }, [
            _vm._v("手机号：" + _vm._s(_vm.orderDatalist.userPhone) + "\n    "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "print-item f-s tc" }, [
            _vm._v("\n      *"),
            _c("span", { staticClass: "space" }, [_vm._v(" ")]),
            _vm._v("*"),
            _c("span", { staticClass: "space" }, [_vm._v(" ")]),
            _vm._v("*"),
            _c("span", { staticClass: "space" }, [_vm._v(" ")]),
            _vm._v(_vm._s(_vm.orderDatalist.flowingCode)),
            _c("span", { staticClass: "space" }, [_vm._v(" ")]),
            _vm._v("运单号*"),
            _c("span", { staticClass: "space" }, [_vm._v(" ")]),
            _vm._v("*"),
            _c("span", { staticClass: "space" }, [_vm._v(" ")]),
            _vm._v("*\n    "),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "br" }),
          _vm._v(" "),
          _c("div", { staticClass: "dashed" }),
          _vm._v(" "),
          _c("p", { staticClass: "br" }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "print-item f-s tl bold" }, [
      _vm._v("\n      商品名称"),
      _c("span", { staticClass: "space" }, [_vm._v(" ")]),
      _c("span", { staticClass: "space" }, [_vm._v(" ")]),
      _c("span", { staticClass: "space" }, [_vm._v(" ")]),
      _vm._v("数量"),
      _c("span", { staticClass: "space" }, [_vm._v(" ")]),
      _c("span", { staticClass: "space" }, [_vm._v(" ")]),
      _c("span", { staticClass: "space" }, [_vm._v(" ")]),
      _vm._v("单价"),
      _c("span", { staticClass: "space" }, [_vm._v(" ")]),
      _c("span", { staticClass: "space" }, [_vm._v(" ")]),
      _c("span", { staticClass: "space" }, [_vm._v(" ")]),
      _c("span", { staticClass: "space" }, [_vm._v(" ")]),
      _vm._v("金额"),
      _c("span", { staticClass: "space" }, [_vm._v(" ")]),
      _c("span", { staticClass: "space" }, [_vm._v(" ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }