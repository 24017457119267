"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      itemList: [{
        name: "消费属性",
        child: [{
          id: 1,
          name: "消费金额"
        }, {
          id: 2,
          name: "消费频次"
        }, {
          id: 3,
          name: "单笔消费金额"
        }]
      }, {
        name: "基本信息",
        child: [{
          id: 4,
          name: "性别"
        }, {
          id: 5,
          name: "年龄"
        }, {
          id: 6,
          name: "职业"
        }]
      }],
      // 选中列表
      checkList: [],
      input: "",
      sex: ''
    };
  },
  methods: {
    handleChange: function handleChange(val) {
      this.checkList = val;
      console.log(this.checkList);
    }
  }
};
exports.default = _default;