"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _order = require("@/api/order");

var _ZBParser = _interopRequireDefault(require("@/components/FormGenerator/components/parser/ZBParser"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    zbParser: _ZBParser.default
  },
  data: function data() {
    return {
      RefuseVisible: false,
      RefuseData: {},
      refundVisible: false,
      refundData: {},
      orderDatalist: null,
      result: [],
      orderids: "",
      orderInfo: []
    };
  },
  mounted: function mounted() {
    var orderId = this.$route.query.orderId;
    this.getDetail(orderId);
  },
  methods: {
    getDetail: function getDetail(id) {
      var _this = this;

      (0, _order.orderDetailApi)({
        orderNo: id
      }).then(function (res) {
        _this.orderDatalist = res;
        _this.orderInfo = res.orderInfo;
        console.log(_this.orderDatalist);
      }).catch(function () {
        _this.orderDatalist = null;
      });
    },
    Print: function Print() {
      this.$router.push({
        path: "/print",
        query: {
          orderData: this.orderDatalist
        }
      });
    },
    RefusehandleClose: function RefusehandleClose() {
      this.RefuseVisible = false;
    },
    onOrderRefuse: function onOrderRefuse() {
      this.orderids = this.$route.query.orderId;
      console.log(this.orderids);
      this.RefuseData = {
        orderId: this.orderids,
        reason: ""
      };
      this.RefuseVisible = true;
    },
    RefusehandlerSubmit: function RefusehandlerSubmit(formValue) {
      var _this2 = this;

      (0, _order.orderRefuseApi)({
        orderNo: this.orderids,
        reason: formValue.reason
      }).then(function (data) {
        _this2.$message.success("操作成功");

        _this2.RefuseVisible = false;
      });
    },
    resetFormRefusehand: function resetFormRefusehand() {
      this.RefuseVisible = false;
    },
    // 立即退款
    refundhandleClose: function refundhandleClose() {
      this.refundVisible = false;
    },
    onOrderRefund: function onOrderRefund() {
      this.orderids = this.$route.query.orderId;
      var payPrice = this.orderDatalist.payPrice;
      this.refundData = {
        orderId: this.orderids,
        amount: payPrice,
        type: ""
      };
      this.refundVisible = true;
    },
    refundhandlerSubmit: function refundhandlerSubmit(formValue) {
      var _this3 = this;

      (0, _order.orderRefundApi)({
        amount: formValue.amount,
        orderNo: this.orderids
      }).then(function (data) {
        _this3.$message.success("操作成功");

        _this3.refundVisible = false;

        _this3.getList();
      });
    },
    resetFormRefundhandler: function resetFormRefundhandler() {
      this.refundVisible = false;
    }
  }
};
exports.default = _default;