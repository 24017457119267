"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _layout = _interopRequireDefault(require("@/layout"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var financialRouter = {
  path: '/financial',
  component: _layout.default,
  redirect: '/financial',
  name: 'Financial',
  meta: {
    title: '财务',
    icon: 'clipboard'
  },
  children: [{
    path: 'commission',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/financial/index'));
      });
    },
    name: 'Commission',
    meta: {
      title: '财务操作',
      icon: ''
    },
    alwaysShow: true,
    children: [{
      path: 'template',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/financial/commission/withdrawal/index'));
        });
      },
      name: 'commissionTemplate',
      meta: {
        title: '申请提现',
        icon: ''
      }
    }]
  }, {
    path: 'record',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/financial/record/index'));
      });
    },
    name: 'financialRecord',
    meta: {
      title: '财务记录',
      icon: ''
    },
    alwaysShow: true,
    children: [{
      path: 'charge',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/financial/record/charge/index'));
        });
      },
      name: 'Charge',
      meta: {
        title: '充值记录',
        icon: ''
      }
    }, {
      path: 'monitor',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/financial/record/monitor/index'));
        });
      },
      name: 'Monitor',
      meta: {
        title: '资金监控',
        icon: ''
      }
    }]
  }, {
    path: 'brokerage',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/financial/brokerage/index'));
      });
    },
    name: 'Brokerage',
    meta: {
      title: '佣金记录',
      icon: ''
    }
  }]
};
var _default = financialRouter;
exports.default = _default;