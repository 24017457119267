"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _financial = require("@/api/financial");

var _index = _interopRequireDefault(require("@/components/cards/index"));

var _ZBParser = _interopRequireDefault(require("@/components/FormGenerator/components/parser/ZBParser"));

var _permission = require("@/utils/permission");

var _validate = require("@/utils/validate");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = {
  name: 'AccountsExtract',
  components: {
    cardsData: _index.default,
    zbParser: _ZBParser.default
  },
  data: function data() {
    return {
      editData: {},
      isCreate: 1,
      dialogVisible: false,
      timeVal: [],
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true,
      tableFrom: {
        extractType: '',
        status: '',
        dateLimit: '',
        keywords: '',
        page: 1,
        limit: 20
      },
      fromList: this.$constants.fromList,
      cardLists: [],
      applyId: null,
      extractType: ''
    };
  },
  mounted: function mounted() {
    this.getList();
    this.getBalance();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    resetForm: function resetForm() {
      this.dialogVisible = false;
    },
    handleEdit: function handleEdit(row) {
      this.extractType = row.extractType;
      this.applyId = row.id;
      this.dialogVisible = true;
      this.isCreate = 1;
      this.editData = JSON.parse(JSON.stringify(row));
    },
    handlerSubmit: (0, _validate.Debounce)(function (formValue) {
      var _this = this;

      formValue.id = this.applyId;
      formValue.extractType = this.extractType;
      (0, _financial.applyUpdateApi)(formValue).then(function (data) {
        _this.$message.success('编辑成功');

        _this.dialogVisible = false;

        _this.getList();
      });
    }),
    handleClose: function handleClose() {
      this.dialogVisible = false;
      this.editData = {};
    },
    onExamine: function onExamine(id) {
      var _this2 = this;

      this.$prompt('未通过', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputErrorMessage: '请输入原因',
        inputType: 'textarea',
        inputValue: '输入信息不完整或有误!',
        inputPlaceholder: '请输入原因',
        inputValidator: function inputValidator(value) {
          if (!value) {
            return '请输入原因';
          }
        }
      }).then(function (_ref) {
        var value = _ref.value;
        (0, _financial.applyStatusApi)({
          id: id,
          status: -1,
          backMessage: value
        }).then(function (res) {
          _this2.$message({
            type: 'success',
            message: '提交成功'
          });

          _this2.getList();
        });
      }).catch(function () {
        _this2.$message({
          type: 'info',
          message: '取消输入'
        });
      });
    },
    ok: function ok(id) {
      var _this3 = this;

      this.$modalSure('审核通过吗').then(function () {
        (0, _financial.applyStatusApi)({
          id: id,
          status: 1
        }).then(function () {
          _this3.$message.success('操作成功');

          _this3.getList();
        });
      });
    },
    // 金额
    getBalance: function getBalance() {
      var _this4 = this;

      (0, _financial.applyBalanceApi)({
        dateLimit: this.tableFrom.dateLimit
      }).then(function (res) {
        _this4.cardLists = [{
          name: '待提现金额',
          count: res.toBeWithdrawn,
          color: '#1890FF',
          class: 'one',
          icon: 'iconzhichujine1'
        }, {
          name: '佣金总金额',
          count: res.commissionTotal,
          color: '#A277FF',
          class: 'two',
          icon: 'iconzhifuyongjinjine1'
        }, {
          name: '已提现金额',
          count: res.withdrawn,
          color: '#EF9C20',
          class: 'three',
          icon: 'iconyingyee1'
        }, {
          name: '未提现金额',
          count: res.unDrawn,
          color: '#1BBE6B',
          class: 'four',
          icon: 'iconyuezhifujine2'
        }];
      });
    },
    // 选择时间
    selectChange: function selectChange(tab) {
      this.timeVal = [];
      this.tableFrom.dateLimit = tab;
      this.tableFrom.page = 1;
      this.getList();
      this.getBalance();
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.dateLimit = e ? this.timeVal.join(',') : '';
      this.tableFrom.page = 1;
      this.getList();
      this.getBalance();
    },
    // 列表
    getList: function getList(num) {
      var _this5 = this;

      this.listLoading = true;
      this.tableFrom.page = num ? num : this.tableFrom.page;
      (0, _financial.applyListApi)(this.tableFrom).then(function (res) {
        _this5.tableData.data = res.list;
        _this5.tableData.total = res.total;
        _this5.listLoading = false;
      }).catch(function () {
        _this5.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    }
  }
};
exports.default = _default;