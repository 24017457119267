var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Box" },
    [
      _c("el-card", [
        _c(
          "div",
          { staticClass: "line-ht" },
          [
            _vm._v("生成的商品默认是没有上架的，请手动上架商品！\n      "),
            _vm.copyConfig.copyType && _vm.copyConfig.copyType == 1
              ? _c(
                  "span",
                  [
                    _vm._v(
                      "您当前剩余" +
                        _vm._s(_vm.copyConfig.copyNum) +
                        "条采集次数，\n        "
                    ),
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: { path: "/operation/systemSms/pay?type=copy" },
                        },
                      },
                      [
                        _c("span", { staticStyle: { color: "#1890ff" } }, [
                          _vm._v("增加采集次数"),
                        ]),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.copyConfig.copyType && _vm.copyConfig.copyType != 1
              ? _c(
                  "el-link",
                  {
                    attrs: {
                      type: "primary",
                      underline: false,
                      href: "https://help.crmeb.net/crmeb_java/2103903",
                      target: "_blank",
                    },
                  },
                  [_vm._v("如何配置密钥\n      ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("br"),
            _vm._v(
              "\n      商品采集设置：设置 > 系统设置 > 第三方接口设置 > 采集商品配置（如配置一号通采集，请先登录一号通账号，无一号通，请选择99Api设置）\n    "
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "formValidate",
          staticClass: "formValidate mt20",
          attrs: {
            model: _vm.formValidate,
            rules: _vm.ruleInline,
            "label-width": "120px",
          },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _vm.copyConfig.copyType && _vm.copyConfig.copyType != 1
            ? _c(
                "el-form-item",
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form,
                        callback: function ($$v) {
                          _vm.form = $$v
                        },
                        expression: "form",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("淘宝")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [_vm._v("京东")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 5 } }, [_vm._v("天猫")]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _vm.copyConfig.copyType
                ? _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "链接地址：" } },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "selWidth",
                              attrs: {
                                placeholder: "请输入链接地址",
                                size: "small",
                              },
                              model: {
                                value: _vm.url,
                                callback: function ($$v) {
                                  _vm.url = $$v
                                },
                                expression: "url",
                              },
                            },
                            [
                              _c("el-button", {
                                directives: [
                                  {
                                    name: "hasPermi",
                                    rawName: "v-hasPermi",
                                    value: [
                                      "admin:product:copy:product",
                                      "admin:product:import:product",
                                    ],
                                    expression:
                                      "['admin:product:copy:product','admin:product:import:product']",
                                  },
                                ],
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                  size: "small",
                                },
                                on: { click: _vm.add },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.formValidate
                ? _c(
                    "el-col",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "商品名称：", prop: "storeName" },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "249",
                                  placeholder: "请输入商品名称",
                                },
                                model: {
                                  value: _vm.formValidate.storeName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formValidate, "storeName", $$v)
                                  },
                                  expression: "formValidate.storeName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "商品简介：" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "250",
                                  type: "textarea",
                                  rows: 3,
                                  placeholder: "请输入商品简介",
                                },
                                model: {
                                  value: _vm.formValidate.storeInfo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formValidate, "storeInfo", $$v)
                                  },
                                  expression: "formValidate.storeInfo",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "商品分类：", prop: "cateIds" } },
                            [
                              _c("el-cascader", {
                                staticClass: "selWidth",
                                attrs: {
                                  options: _vm.merCateList,
                                  props: _vm.props2,
                                  clearable: "",
                                  "show-all-levels": false,
                                },
                                model: {
                                  value: _vm.formValidate.cateIds,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formValidate, "cateIds", $$v)
                                  },
                                  expression: "formValidate.cateIds",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "商品关键字：", prop: "keyword" },
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入商品关键字" },
                                model: {
                                  value: _vm.formValidate.keyword,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formValidate, "keyword", $$v)
                                  },
                                  expression: "formValidate.keyword",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        _vm._b({}, "el-col", _vm.grid, false),
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "单位：", prop: "unitName" } },
                            [
                              _c("el-input", {
                                staticClass: "selWidth",
                                attrs: { placeholder: "请输入单位" },
                                model: {
                                  value: _vm.formValidate.unitName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formValidate, "unitName", $$v)
                                  },
                                  expression: "formValidate.unitName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        _vm._b({}, "el-col", _vm.grid, false),
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "运费模板：", prop: "tempId" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "selWidth",
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.formValidate.tempId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formValidate, "tempId", $$v)
                                    },
                                    expression: "formValidate.tempId",
                                  },
                                },
                                _vm._l(_vm.shippingList, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "商品封面图：", prop: "image" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "upLoadPicBox",
                                  on: {
                                    click: function ($event) {
                                      return _vm.modalPicTap("1")
                                    },
                                  },
                                },
                                [
                                  _vm.formValidate.image
                                    ? _c("div", { staticClass: "pictrue" }, [
                                        _c("img", {
                                          attrs: {
                                            src: _vm.formValidate.image,
                                          },
                                        }),
                                      ])
                                    : _c("div", { staticClass: "upLoad" }, [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-camera cameraIconfont",
                                        }),
                                      ]),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "商品轮播图：" } },
                            [
                              _c(
                                "div",
                                { staticClass: "acea-row" },
                                _vm._l(
                                  _vm.formValidate.sliderImages,
                                  function (item, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass: "lunBox mr5",
                                        attrs: { draggable: "false" },
                                        on: {
                                          dragstart: function ($event) {
                                            return _vm.handleDragStart(
                                              $event,
                                              item
                                            )
                                          },
                                          dragover: function ($event) {
                                            $event.preventDefault()
                                            return _vm.handleDragOver(
                                              $event,
                                              item
                                            )
                                          },
                                          dragenter: function ($event) {
                                            return _vm.handleDragEnter(
                                              $event,
                                              item
                                            )
                                          },
                                          dragend: function ($event) {
                                            return _vm.handleDragEnd(
                                              $event,
                                              item
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("div", { staticClass: "pictrue" }, [
                                          _c("img", { attrs: { src: item } }),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "el-button-group",
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: { size: "mini" },
                                                nativeOn: {
                                                  click: function ($event) {
                                                    return _vm.checked(
                                                      item,
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("主图")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-button",
                                              {
                                                attrs: { size: "mini" },
                                                nativeOn: {
                                                  click: function ($event) {
                                                    return _vm.handleRemove(
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("移除")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.formValidate.specType || _vm.formValidate.attr.length
                        ? _c(
                            "el-col",
                            { staticClass: "noForm", attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "labeltop",
                                  attrs: { label: "批量设置：" },
                                },
                                [
                                  _c(
                                    "el-table",
                                    {
                                      staticClass: "tabNumWidth",
                                      attrs: {
                                        data: _vm.oneFormBatch,
                                        border: "",
                                        size: "mini",
                                      },
                                    },
                                    [
                                      _c("el-table-column", {
                                        attrs: {
                                          align: "center",
                                          label: "图片",
                                          "min-width": "80",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "upLoadPicBox",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.modalPicTap(
                                                            "1",
                                                            "pi"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      scope.row.image
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "pictrue pictrueTab",
                                                            },
                                                            [
                                                              _c("img", {
                                                                attrs: {
                                                                  src: scope.row
                                                                    .image,
                                                                },
                                                              }),
                                                            ]
                                                          )
                                                        : _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "upLoad pictrueTab",
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "el-icon-camera cameraIconfont",
                                                              }),
                                                            ]
                                                          ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          3789294462
                                        ),
                                      }),
                                      _vm._v(" "),
                                      _vm._l(
                                        _vm.attrValue,
                                        function (item, iii) {
                                          return _c("el-table-column", {
                                            key: iii,
                                            attrs: {
                                              label: _vm.formThead[iii].title,
                                              align: "center",
                                              "min-width": "120",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c("el-input", {
                                                        staticClass: "priceBox",
                                                        attrs: {
                                                          type:
                                                            _vm.formThead[iii]
                                                              .title ===
                                                            "商品编号"
                                                              ? "text"
                                                              : "number",
                                                          min: 0,
                                                        },
                                                        model: {
                                                          value: scope.row[iii],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              scope.row,
                                                              iii,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "scope.row[iii]",
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          })
                                        }
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-table-column",
                                        {
                                          attrs: {
                                            align: "center",
                                            label: "操作",
                                            "min-width": "80",
                                          },
                                        },
                                        [
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                staticClass: "submission",
                                                attrs: { type: "text" },
                                                on: { click: _vm.batchAdd },
                                              },
                                              [_vm._v("批量添加")]
                                            ),
                                          ],
                                        ],
                                        2
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "商品规格：",
                                props: "spec_type",
                                "label-for": "spec_type",
                              },
                            },
                            [
                              _c(
                                "el-table",
                                {
                                  staticClass: "tabNumWidth",
                                  attrs: {
                                    data: _vm.formValidate.attrValue,
                                    border: "",
                                    size: "mini",
                                  },
                                },
                                [
                                  _vm.manyTabDate
                                    ? _vm._l(
                                        _vm.manyTabDate,
                                        function (item, iii) {
                                          return _c("el-table-column", {
                                            key: iii,
                                            attrs: {
                                              align: "center",
                                              label: _vm.manyTabTit[iii].title,
                                              "min-width": "80",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c("span", {
                                                        staticClass: "priceBox",
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            scope.row[iii]
                                                          ),
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          })
                                        }
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      label: "图片",
                                      "min-width": "80",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    rules: [
                                                      {
                                                        required: true,
                                                        message: "请上传图片",
                                                        trigger: "change",
                                                      },
                                                    ],
                                                    prop:
                                                      "attrValue." +
                                                      scope.$index +
                                                      ".image",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "upLoadPicBox",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.modalPicTap(
                                                            "1",
                                                            "duo",
                                                            scope.$index
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      scope.row.image
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "pictrue pictrueTab",
                                                            },
                                                            [
                                                              _c("img", {
                                                                attrs: {
                                                                  src: scope.row
                                                                    .image,
                                                                },
                                                              }),
                                                            ]
                                                          )
                                                        : _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "upLoad pictrueTab",
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "el-icon-camera cameraIconfont",
                                                              }),
                                                            ]
                                                          ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      799156793
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _vm._l(_vm.attrValue, function (item, iii) {
                                    return _c("el-table-column", {
                                      key: iii,
                                      attrs: {
                                        label: _vm.formThead[iii].title,
                                        align: "center",
                                        "min-width": "120",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      rules: [
                                                        {
                                                          required: true,
                                                          message:
                                                            "请输入" +
                                                            _vm.formThead[iii]
                                                              .title,
                                                          trigger: "blur",
                                                        },
                                                      ],
                                                      prop:
                                                        _vm.formThead[iii]
                                                          .title !== "商品编号"
                                                          ? "attrValue." +
                                                            scope.$index +
                                                            "." +
                                                            iii
                                                          : "",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticClass: "priceBox",
                                                      attrs: {
                                                        type:
                                                          _vm.formThead[iii]
                                                            .title ===
                                                          "商品编号"
                                                            ? "text"
                                                            : "number",
                                                      },
                                                      model: {
                                                        value: scope.row[iii],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            iii,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row[iii]",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    })
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      label: "操作",
                                      "min-width": "80",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "el-button",
                                                {
                                                  staticClass: "submission",
                                                  attrs: { type: "text" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.delAttrTable(
                                                        scope.$index
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("删除")]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2803824461
                                    ),
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "商品详情：" } },
                            [
                              _c("Tinymce", {
                                model: {
                                  value: _vm.formValidate.content,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formValidate, "content", $$v)
                                  },
                                  expression: "formValidate.content",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          [
                            _c(
                              "el-row",
                              { attrs: { gutter: 24 } },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 24 } },
                                  [
                                    [
                                      _c(
                                        "el-row",
                                        { attrs: { gutter: 24 } },
                                        [
                                          _c(
                                            "el-col",
                                            { attrs: { span: 8 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                { attrs: { label: "排序：" } },
                                                [
                                                  _c("el-input-number", {
                                                    attrs: {
                                                      max: 9999,
                                                      placeholder: "请输入排序",
                                                      disabled: _vm.isDisabled,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.formValidate.sort,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.formValidate,
                                                          "sort",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "formValidate.sort",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 8 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                { attrs: { label: "积分：" } },
                                                [
                                                  _c("el-input-number", {
                                                    attrs: {
                                                      placeholder: "请输入排序",
                                                      disabled: _vm.isDisabled,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.formValidate
                                                          .giveIntegral,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.formValidate,
                                                          "giveIntegral",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "formValidate.giveIntegral",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 8 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "虚拟销量：",
                                                  },
                                                },
                                                [
                                                  _c("el-input-number", {
                                                    attrs: {
                                                      placeholder: "请输入排序",
                                                      disabled: _vm.isDisabled,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.formValidate.ficti,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.formValidate,
                                                          "ficti",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "formValidate.ficti",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  { attrs: { span: 24 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "商品推荐：" } },
                                      [
                                        _c(
                                          "el-checkbox-group",
                                          {
                                            attrs: {
                                              size: "small",
                                              disabled: _vm.isDisabled,
                                            },
                                            on: { change: _vm.onChangeGroup },
                                            model: {
                                              value: _vm.checkboxGroup,
                                              callback: function ($$v) {
                                                _vm.checkboxGroup = $$v
                                              },
                                              expression: "checkboxGroup",
                                            },
                                          },
                                          _vm._l(
                                            _vm.recommend,
                                            function (item, index) {
                                              return _c(
                                                "el-checkbox",
                                                {
                                                  key: index,
                                                  attrs: { label: item.value },
                                                },
                                                [_vm._v(_vm._s(item.name))]
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "submission",
                                  attrs: {
                                    type: "primary",
                                    loading: _vm.modal_loading,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleSubmit("formValidate")
                                    },
                                  },
                                },
                                [_vm._v("提交\n            ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }