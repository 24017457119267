var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "head" },
        [
          _c(
            "el-card",
            { staticClass: "box-card" },
            [
              _c(
                "el-form",
                {
                  attrs: {
                    model: _vm.orderDatalist,
                    "label-width": "80px",
                    size: "size",
                    "label-position": "left",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { staticClass: "col", attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "item_billcode",
                              attrs: { label: "订单号:" },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "item_result_billcode" },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.orderDatalist.orderId) +
                                      "\n                "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { staticClass: "col", attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "item_billcode",
                              attrs: { label: "所属门店:" },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "item_result_billcode" },
                                [_vm._v("Granvida生活超市")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { staticClass: "col", attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "item_billcode",
                              attrs: { label: "下单时间:" },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "item_result_billcode" },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.orderDatalist.createTime) +
                                      "\n                "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { staticClass: "col", attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "item",
                              attrs: { label: "买家备注:" },
                            },
                            [
                              _c("div", { staticClass: "item_result" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.orderDatalist.remark) +
                                    "\n                "
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { staticClass: "col", attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "item",
                              attrs: { label: "客户实付:" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "item_result",
                                  staticStyle: { color: "#f56c6c" },
                                },
                                [
                                  _vm._v(
                                    "\n                  ￥" +
                                      _vm._s(_vm.orderDatalist.payPrice) +
                                      "\n                "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "yonghu" },
        [
          _c(
            "el-card",
            { staticClass: "yonghu-info" },
            [
              _c(
                "el-form",
                {
                  attrs: {
                    model: _vm.orderDatalist,
                    "label-width": "80px",
                    size: "size",
                    "label-position": "left",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { staticClass: "col", attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "item_billcode",
                              attrs: { label: "用户：" },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "item_result_billcode" },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.orderDatalist.nikeName) +
                                      "\n                "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { staticClass: "col", attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "item_billcode",
                              attrs: { label: "手机号：" },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "item_result_billcode" },
                                [_vm._v(_vm._s(_vm.orderDatalist.phone))]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "address" },
        [
          _c(
            "el-card",
            { staticClass: "address-info" },
            [
              _c(
                "el-form",
                {
                  attrs: {
                    model: _vm.orderDatalist,
                    "label-width": "80px",
                    size: "size",
                    "label-position": "left",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { staticClass: "col", attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "item_billcode",
                              attrs: { label: "收货人：" },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "item_result_billcode" },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.orderDatalist.realName) +
                                      "\n                "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { staticClass: "col", attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "item_billcode",
                              attrs: { label: "收货电话:" },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "item_result_billcode" },
                                [_vm._v(_vm._s(_vm.orderDatalist.userPhone))]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { staticClass: "col", attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "item_billcode",
                              attrs: { label: "收货地址:" },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "item_result_billcode" },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.orderDatalist.userAddress) +
                                      "\n                "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "main-head" }, [
        _c("span", [_vm._v("商品详情")]),
        _vm._v(" "),
        _c("div", { staticClass: "count" }, [_vm._v("总件数:")]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticStyle: {
              "font-size": "15px",
              "line-height": "40px",
              "margin-left": "20px",
              color: "#f56c6c",
            },
          },
          [
            _vm._v(
              "\n        " + _vm._s(_vm.orderDatalist.totalNum) + "\n      "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticStyle: {
              "font-size": "15px",
              "line-height": "40px",
              "margin-left": "20px",
            },
          },
          [_vm._v("\n        合计:\n      ")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticStyle: {
              "font-size": "15px",
              "line-height": "40px",
              "margin-left": "20px",
              color: "#f56c6c",
            },
          },
          [
            _vm._v(
              "\n        " + _vm._s(_vm.orderDatalist.totalPrice) + "\n      "
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "main" },
        [
          _c(
            "el-card",
            { staticClass: "box-card" },
            _vm._l(_vm.orderInfo, function (item, index) {
              return _c("div", { key: index, staticClass: "info" }, [
                _c("div", { staticClass: "image" }, [
                  _c("img", { attrs: { src: item.info.image } }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "name" }, [
                  _c("div", [_vm._v(_vm._s(item.info.productName))]),
                  _vm._v(" "),
                  _c("div", { staticClass: "plucode" }, [
                    _c("span", [_vm._v("商品店内码:")]),
                    _vm._v(" "),
                    _c("div", [_vm._v(_vm._s(item.info.plucode))]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "barCode" }, [
                    _c("span", [_vm._v("商品条码:")]),
                    _vm._v(" "),
                    _c("div", [_vm._v(_vm._s(item.info.barCode))]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "productprice" }, [
                  _c("span", [_vm._v("商品单价:")]),
                  _vm._v(" "),
                  _c("div", [_vm._v("￥" + _vm._s(item.info.price))]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "counts" }, [
                  _c("span", [_vm._v("商品数量:")]),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(item.info.payNum))]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "price" }, [
                  _c("span", [_vm._v("商品金额:")]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(_vm._s(item.info.payNum * item.info.price)),
                  ]),
                ]),
              ])
            }),
            0
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "foot" },
        [
          _c("el-card", [
            _c("div", { staticClass: "foot-info" }, [
              _c("div", { staticClass: "foot-info-name" }, [
                _c("div", { staticClass: "foot-name" }, [_vm._v("支付方式:")]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.orderDatalist.payTypeStr))]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "foot-info-name" }, [
                _c("div", { staticClass: "foot-name" }, [_vm._v("商品金额:")]),
                _vm._v(" "),
                _c("span", [
                  _vm._v("￥" + _vm._s(_vm.orderDatalist.totalPrice)),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "foot-info-name" }, [
                _c("div", { staticClass: "foot-name" }, [_vm._v("运费:")]),
                _vm._v(" "),
                _c("span", [
                  _vm._v("￥" + _vm._s(_vm.orderDatalist.payPostage)),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "foot-info-name" }, [
                _c("div", { staticClass: "foot-name" }, [
                  _vm._v("优惠券金额:"),
                ]),
                _vm._v(" "),
                _c("span", [
                  _vm._v("￥" + _vm._s(_vm.orderDatalist.couponPrice)),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "foot-info-name" }, [
                _c("div", { staticClass: "foot-name" }, [_vm._v("抵扣积分:")]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(_vm._s(_vm.orderDatalist.totalPoints || 0)),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "foot-price" }, [
              _c("div", [_vm._v("客户应付金额:")]),
              _vm._v(" "),
              _c("span", [_vm._v("￥" + _vm._s(_vm.orderDatalist.totalPrice))]),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.orderDatalist.refundStatus === 1 ||
      _vm.orderDatalist.refundStatus === 2
        ? _c(
            "div",
            { staticClass: "refund" },
            [
              _c("span", [_vm._v("退款")]),
              _vm._v(" "),
              _c(
                "el-card",
                [
                  _c(
                    "el-form",
                    {
                      attrs: {
                        model: _vm.orderDatalist,
                        "label-width": "80px",
                        size: "size",
                        "label-position": "left",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { staticClass: "col", attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "item_billcode",
                                  attrs: { label: "退款原因:" },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "item_result_billcode" },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.orderDatalist.refundReasonWap
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { staticClass: "col", attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "item_billcode",
                                  attrs: { label: "退款时间:" },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "item_result_billcode" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.orderDatalist.refundReasonTime
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { staticClass: "col", attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "item_billcode",
                                  attrs: { label: "备注说明:" },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "item_result_billcode" },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.orderDatalist
                                              .refundReasonWapExplain
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { staticClass: "col", attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "item_billcode",
                                  attrs: { label: "退款凭证:" },
                                },
                                _vm._l(
                                  _vm.orderDatalist.refundReasonWapImg.split(
                                    ","
                                  ),
                                  function (item, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass: "item_result_billcode",
                                        staticStyle: {
                                          width: "35px",
                                          height: "auto",
                                          display: "inline-block",
                                        },
                                      },
                                      [
                                        item != ""
                                          ? _c("el-image", {
                                              attrs: {
                                                src: item,
                                                "preview-src-list": [item],
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.orderDatalist.refundStatus === 0 &&
      _vm.orderDatalist.refundReasonWap !== null
        ? _c(
            "div",
            { staticClass: "refund" },
            [
              _c("span", [_vm._v("退款")]),
              _vm._v(" "),
              _c(
                "el-card",
                [
                  _c(
                    "el-form",
                    {
                      attrs: {
                        model: _vm.orderDatalist,
                        "label-width": "90px",
                        size: "size",
                        "label-position": "left",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { staticClass: "col", attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "item_billcode",
                                  attrs: { label: "不退款理由:" },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "item_result_billcode" },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.orderDatalist.refundReason
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "foot-button" }, [
        _c(
          "div",
          { staticStyle: { margin: "0 auto" } },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", round: "" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.Print()
                  },
                },
              },
              [_vm._v("打印小票")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticStyle: { margin: "0 auto" } },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", round: "" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.PrintClosing()
                  },
                },
              },
              [_vm._v("结账小票")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm.orderDatalist.statusStr.key === "notShipped" &&
        _vm.orderDatalist.refundStatus === 0
          ? _c(
              "div",
              { staticStyle: { margin: "0 auto" } },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", round: "" },
                    on: {
                      click: function ($event) {
                        return _vm.sendOrder()
                      },
                    },
                  },
                  [_vm._v(" 发货送货 ")]
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.orderDatalist.refundStatus === 1
          ? _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  margin: "0 auto",
                  "margin-left": "-50px",
                },
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "success", round: "" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.onOrderRefuse()
                      },
                    },
                  },
                  [_vm._v("拒绝退款")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "danger", round: "" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.onOrderRefund()
                      },
                    },
                  },
                  [_vm._v("立即退款")]
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticStyle: { height: "20px" } }),
      _vm._v(" "),
      _c("order-send", {
        ref: "send",
        attrs: { orderId: _vm.orderId },
        on: {
          submitFail: function ($event) {
            return _vm.getDetail(_vm.orderId)
          },
        },
      }),
      _vm._v(" "),
      _c("order-video-send", {
        ref: "videoSend",
        attrs: { orderId: _vm.orderId },
        on: { submitFail: _vm.getList },
      }),
      _vm._v(" "),
      _vm.RefuseVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "拒绝退款原因",
                visible: _vm.RefuseVisible,
                width: "500px",
                "before-close": _vm.RefusehandleClose,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.RefuseVisible = $event
                },
              },
            },
            [
              _c("zb-parser", {
                attrs: {
                  "form-id": 106,
                  "is-create": 1,
                  "edit-data": _vm.RefuseData,
                },
                on: {
                  submit: _vm.RefusehandlerSubmit,
                  resetForm: _vm.resetFormRefusehand,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "退款处理",
            visible: _vm.refundVisible,
            width: "500px",
            "before-close": _vm.refundhandleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.refundVisible = $event
            },
          },
        },
        [
          _vm.refundVisible
            ? _c("zb-parser", {
                attrs: {
                  "form-id": 107,
                  "is-create": 1,
                  "edit-data": _vm.refundData,
                },
                on: {
                  submit: _vm.refundhandlerSubmit,
                  resetForm: _vm.resetFormRefundhandler,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }