"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _order = require("@/api/order");

var _ZBParser = _interopRequireDefault(require("@/components/FormGenerator/components/parser/ZBParser"));

var _orderSend = _interopRequireDefault(require("./orderSend"));

var _orderVideoSend = _interopRequireDefault(require("./orderVideoSend"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    zbParser: _ZBParser.default,
    orderSend: _orderSend.default,
    orderVideoSend: _orderVideoSend.default
  },
  data: function data() {
    return {
      RefuseVisible: false,
      RefuseData: {},
      refundVisible: false,
      refundData: {},
      orderDatalist: null,
      result: [],
      orderids: "",
      orderInfo: [],
      orderId: ''
    };
  },
  mounted: function mounted() {
    this.orderId = this.$route.query.orderId;
    var orderId = this.$route.query.orderId;
    this.getDetail(orderId);
  },
  methods: {
    getDetail: function getDetail(id) {
      var _this = this;

      (0, _order.orderDetailApi)({
        orderNo: id
      }).then(function (res) {
        _this.orderDatalist = res;
        _this.orderInfo = res.orderInfo;
        console.log(_this.orderDatalist);
      }).catch(function () {
        _this.orderDatalist = null;
      });
    },
    Print: function Print() {
      this.$router.push({
        path: "/print",
        query: {
          orderData: this.orderDatalist
        }
      });
    },
    PrintClosing: function PrintClosing() {
      this.$router.push({
        path: "/printclosing",
        query: {
          orderData: this.orderDatalist
        }
      });
    },
    RefusehandleClose: function RefusehandleClose() {
      this.RefuseVisible = false;
    },
    onOrderRefuse: function onOrderRefuse() {
      this.orderids = this.$route.query.orderId;
      console.log(this.orderids);
      this.RefuseData = {
        orderId: this.orderids,
        reason: ""
      };
      this.RefuseVisible = true;
    },
    RefusehandlerSubmit: function RefusehandlerSubmit(formValue) {
      var _this2 = this;

      (0, _order.orderRefuseApi)({
        orderNo: this.orderids,
        reason: formValue.reason
      }).then(function (data) {
        _this2.$message.success("操作成功");

        _this2.RefuseVisible = false;

        _this2.getDetail(_this2.$route.query.orderId);
      });
    },
    resetFormRefusehand: function resetFormRefusehand() {
      this.RefuseVisible = false;
    },
    // 立即退款
    refundhandleClose: function refundhandleClose() {
      this.refundVisible = false;
    },
    onOrderRefund: function onOrderRefund() {
      this.orderids = this.$route.query.orderId;
      var payPrice = this.orderDatalist.payPrice;
      var points = this.orderDatalist.totalPoints;
      this.refundData = {
        orderId: this.orderids,
        amount: payPrice,
        type: "",
        points: points || 0
      };
      this.refundVisible = true;
    },
    refundhandlerSubmit: function refundhandlerSubmit(formValue) {
      var _this3 = this;

      (0, _order.orderRefundApi)({
        amount: formValue.amount,
        orderNo: this.orderids
      }).then(function (data) {
        _this3.$message.success("操作成功");

        _this3.refundVisible = false;

        _this3.getDetail(_this3.$route.query.orderId);
      });
    },
    resetFormRefundhandler: function resetFormRefundhandler() {
      this.refundVisible = false;
    },
    // 发送
    sendOrder: function sendOrder() {
      if (this.orderDatalist.type === 0) {
        this.$refs.send.modals = true;
        this.$refs.send.getList();
        this.$refs.send.sheetInfo();
      } else {
        this.$refs.videoSend.modals = true;
        if (!JSON.parse(sessionStorage.getItem('videoExpress'))) this.$refs.videoSend.companyGetList();
      }

      this.orderId = row.orderId;
    },
    // 列表
    getList: function getList() {
      var _this4 = this;

      this.listLoading = true;
      (0, _order.orderListApi)(this.tableFrom).then(function (res) {
        _this4.tableData.data = res.list || [];
        console.log(_this4.tableData.data);
        _this4.tableData.total = res.total;
        _this4.listLoading = false;
        _this4.checkedCities = _this4.$cache.local.has('order_stroge') ? _this4.$cache.local.getJSON('order_stroge') : _this4.checkedCities;
      }).catch(function () {
        _this4.listLoading = false;
      });
    }
  }
};
exports.default = _default;