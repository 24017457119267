var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 6 } }, [
            _c("div", { staticClass: "grid-content bg-purple" }, [
              _c(
                "div",
                { staticClass: "contain-card" },
                _vm._l(_vm.itemList, function (parent) {
                  return _c(
                    "el-card",
                    { key: parent.name, staticClass: "box-card" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "clearfix",
                          attrs: { slot: "header" },
                          slot: "header",
                        },
                        [_c("span", [_vm._v(_vm._s(parent.name))])]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "text item" }, [
                        _c(
                          "div",
                          [
                            _c(
                              "el-checkbox-group",
                              {
                                on: { change: _vm.handleChange },
                                model: {
                                  value: _vm.checkList,
                                  callback: function ($$v) {
                                    _vm.checkList = $$v
                                  },
                                  expression: "checkList",
                                },
                              },
                              _vm._l(parent.child, function (item) {
                                return _c(
                                  "el-checkbox",
                                  {
                                    key: item.id,
                                    staticClass: "card-checkbox",
                                    staticStyle: {
                                      display: "block",
                                      "margin-top": "10px",
                                      "margin-left": "40px",
                                      width: "140px",
                                    },
                                    attrs: { label: item.id, border: "" },
                                  },
                                  [_vm._v(_vm._s(item.name))]
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]
                  )
                }),
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 6 } }, [
            _c("div", { staticClass: "grid-content bg-purple-light" }, [
              _c(
                "div",
                { staticClass: "contain-text" },
                _vm._l(_vm.checkList, function (item) {
                  return _c(
                    "div",
                    { key: item.id, staticClass: "demo-input-suffix" },
                    [
                      item == 1
                        ? _c(
                            "div",
                            { staticClass: "conditional-pop-up" },
                            [
                              _c(
                                "el-row",
                                [
                                  _c("el-col", { attrs: { span: 24 } }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "grid-content bg-purple-dark",
                                      },
                                      [_c("span", [_vm._v("消费金额")])]
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-row",
                                [
                                  _c("el-col", { attrs: { span: 24 } }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "grid-content bg-purple-dark",
                                      },
                                      [
                                        _c("el-input", {
                                          staticStyle: { width: "200px" },
                                          attrs: { placeholder: "请输入金额" },
                                          model: {
                                            value: _vm.input,
                                            callback: function ($$v) {
                                              _vm.input = $$v
                                            },
                                            expression: "input",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", [_vm._v("元~")]),
                                        _vm._v(" "),
                                        _c("el-input", {
                                          staticStyle: { width: "200px" },
                                          attrs: { placeholder: "请输入金额" },
                                          model: {
                                            value: _vm.input,
                                            callback: function ($$v) {
                                              _vm.input = $$v
                                            },
                                            expression: "input",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", [_vm._v("元")]),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      item == 2
                        ? _c(
                            "div",
                            { staticClass: "conditional-pop-up" },
                            [
                              _c(
                                "el-row",
                                [
                                  _c("el-col", { attrs: { span: 24 } }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "grid-content bg-purple-dark",
                                      },
                                      [_c("span", [_vm._v("消费频次")])]
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-row",
                                [
                                  _c("el-col", { attrs: { span: 24 } }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "grid-content bg-purple-dark",
                                      },
                                      [
                                        _c("el-input", {
                                          staticStyle: { width: "200px" },
                                          attrs: { placeholder: "请输入频次" },
                                          model: {
                                            value: _vm.input,
                                            callback: function ($$v) {
                                              _vm.input = $$v
                                            },
                                            expression: "input",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", [_vm._v("次~")]),
                                        _vm._v(" "),
                                        _c("el-input", {
                                          staticStyle: { width: "200px" },
                                          attrs: { placeholder: "请输入频次" },
                                          model: {
                                            value: _vm.input,
                                            callback: function ($$v) {
                                              _vm.input = $$v
                                            },
                                            expression: "input",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", [_vm._v("次")]),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      item == 3
                        ? _c(
                            "div",
                            { staticClass: "conditional-pop-up" },
                            [
                              _c(
                                "el-row",
                                [
                                  _c("el-col", { attrs: { span: 24 } }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "grid-content bg-purple-dark",
                                      },
                                      [_c("span", [_vm._v("单笔消费金额")])]
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-row",
                                [
                                  _c("el-col", { attrs: { span: 24 } }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "grid-content bg-purple-dark",
                                      },
                                      [
                                        _c("el-input", {
                                          staticStyle: { width: "200px" },
                                          attrs: { placeholder: "请输入金额" },
                                          model: {
                                            value: _vm.input,
                                            callback: function ($$v) {
                                              _vm.input = $$v
                                            },
                                            expression: "input",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", [_vm._v("元~")]),
                                        _vm._v(" "),
                                        _c("el-input", {
                                          staticStyle: { width: "200px" },
                                          attrs: { placeholder: "请输入金额" },
                                          model: {
                                            value: _vm.input,
                                            callback: function ($$v) {
                                              _vm.input = $$v
                                            },
                                            expression: "input",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", [_vm._v("元")]),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "contain-text" },
                _vm._l(_vm.checkList, function (item) {
                  return _c(
                    "div",
                    { key: item.id, staticClass: "demo-input-suffix" },
                    [
                      item == 4
                        ? _c(
                            "div",
                            { staticClass: "conditional-pop-up" },
                            [
                              _c(
                                "el-row",
                                [
                                  _c("el-col", { attrs: { span: 24 } }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "grid-content bg-purple-dark",
                                      },
                                      [_c("span", [_vm._v("性别")])]
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-row",
                                [
                                  _c("el-col", { attrs: { span: 24 } }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "grid-content bg-purple-dark",
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          {
                                            attrs: { label: "1", border: "" },
                                            model: {
                                              value: _vm.sex,
                                              callback: function ($$v) {
                                                _vm.sex = $$v
                                              },
                                              expression: "sex",
                                            },
                                          },
                                          [_vm._v("男")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-radio",
                                          {
                                            attrs: { label: "2", border: "" },
                                            model: {
                                              value: _vm.sex,
                                              callback: function ($$v) {
                                                _vm.sex = $$v
                                              },
                                              expression: "sex",
                                            },
                                          },
                                          [_vm._v("女")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      item == 5
                        ? _c(
                            "div",
                            { staticClass: "conditional-pop-up" },
                            [
                              _c(
                                "el-row",
                                [
                                  _c("el-col", { attrs: { span: 24 } }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "grid-content bg-purple-dark",
                                      },
                                      [_c("span", [_vm._v("年龄")])]
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-row",
                                [
                                  _c("el-col", { attrs: { span: 24 } }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "grid-content bg-purple-dark",
                                      },
                                      [
                                        _c("el-input", {
                                          staticStyle: { width: "200px" },
                                          attrs: { placeholder: "请输入年龄" },
                                          model: {
                                            value: _vm.input,
                                            callback: function ($$v) {
                                              _vm.input = $$v
                                            },
                                            expression: "input",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", [_vm._v("岁~")]),
                                        _vm._v(" "),
                                        _c("el-input", {
                                          staticStyle: { width: "200px" },
                                          attrs: {
                                            placeholder:
                                              "请输入年龄\n                      ",
                                          },
                                          model: {
                                            value: _vm.input,
                                            callback: function ($$v) {
                                              _vm.input = $$v
                                            },
                                            expression: "input",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", [_vm._v("岁")]),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      item == 6
                        ? _c(
                            "div",
                            { staticClass: "conditional-pop-up" },
                            [
                              _c(
                                "el-row",
                                [
                                  _c("el-col", { attrs: { span: 24 } }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "grid-content bg-purple-dark",
                                      },
                                      [_c("span", [_vm._v("职业")])]
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-row",
                                [
                                  _c("el-col", { attrs: { span: 24 } }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "grid-content bg-purple-dark",
                                      },
                                      [
                                        _c("el-input", {
                                          staticStyle: { width: "200px" },
                                          attrs: { placeholder: "请输入内容" },
                                          model: {
                                            value: _vm.input,
                                            callback: function ($$v) {
                                              _vm.input = $$v
                                            },
                                            expression: "input",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", [_vm._v("元~")]),
                                        _vm._v(" "),
                                        _c("el-input", {
                                          staticStyle: { width: "200px" },
                                          attrs: { placeholder: "请输入内容" },
                                          model: {
                                            value: _vm.input,
                                            callback: function ($$v) {
                                              _vm.input = $$v
                                            },
                                            expression: "input",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", [_vm._v("元")]),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  )
                }),
                0
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }