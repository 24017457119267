"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var categoryApi = _interopRequireWildcard(require("@/api/categoryApi.js"));

var selfUtil = _interopRequireWildcard(require("@/utils/ZBKJIutil.js"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var _default = {
  // name: "edit"
  props: {
    prent: {
      type: Object,
      required: true
    },
    isCreate: {
      type: Number,
      default: 0
    },
    editData: {
      type: Object
    },
    biztype: {
      type: Object,
      required: true
    },
    allTreeList: {
      type: Array
    }
  },
  data: function data() {
    return {
      loadingBtn: false,
      constants: this.$constants,
      editPram: {
        extra: null,
        name: null,
        egname: null,
        pid: null,
        sort: 0,
        status: true,
        type: this.biztype.value,
        url: null,
        id: 0
      },
      categoryProps: {
        value: 'id',
        label: 'name',
        children: 'child',
        expandTrigger: 'hover',
        checkStrictly: true,
        emitPath: false
      },
      parentOptions: []
    };
  },
  mounted: function mounted() {
    this.initEditData();
  },
  methods: {
    // 点击图标
    addIcon: function addIcon() {
      var _this = this;

      _this.$modalIcon(function (icon) {
        _this.editPram.extra = icon;
      });
    },
    // 点击商品图
    modalPicTap: function modalPicTap(tit, num, i) {
      var _this = this;

      var attr = [];
      this.$modalUpload(function (img) {
        if (tit === '1' && !num) {
          _this.editPram.extra = img[0].sattDir;
        }

        if (tit === '2' && !num) {
          img.map(function (item) {
            attr.push(item.attachment_src);

            _this.formValidate.slider_image.push(item);
          });
        }
      }, tit, 'store');
    },
    close: function close() {
      this.$emit('hideEditDialog');
    },
    initEditData: function initEditData() {
      this.parentOptions = _toConsumableArray(this.allTreeList);
      this.addTreeListLabelForCasCard(this.parentOptions, 'child');
      var _this$editData = this.editData,
          extra = _this$editData.extra,
          name = _this$editData.name,
          egname = _this$editData.egname,
          pid = _this$editData.pid,
          sort = _this$editData.sort,
          status = _this$editData.status,
          type = _this$editData.type,
          id = _this$editData.id,
          url = _this$editData.url;

      if (this.isCreate === 1) {
        this.editPram.extra = extra;
        this.editPram.name = name;
        this.editPram.egname = egname;
        this.editPram.pid = pid;
        this.editPram.sort = sort;
        this.editPram.status = status;
        this.editPram.type = type;
        this.editPram.url = url;
        this.editPram.id = id;
      } else {
        this.editPram.pid = this.prent.id;
        this.editPram.type = this.biztype.value;
      }
    },
    addTreeListLabelForCasCard: function addTreeListLabelForCasCard(arr, child) {
      arr.forEach(function (o, i) {
        if (o.child && o.child.length) {
          // o.disabled = true
          o.child.forEach(function (j) {
            j.disabled = true;
          });
        }
      });
    },
    handlerSubmit: function handlerSubmit(formName) {
      var _this2 = this;

      this.$refs[formName].validate(function (valid) {
        if (!valid) return;

        _this2.handlerSaveOrUpdate(_this2.isCreate === 0);
      });
    },
    handlerSaveOrUpdate: function handlerSaveOrUpdate(isSave) {
      var _this3 = this;

      if (isSave) {
        // this.editPram.pid = this.prent.id
        this.loadingBtn = true;
        categoryApi.addCategroy(this.editPram).then(function (data) {
          _this3.$emit('hideEditDialog');

          _this3.$message.success('创建目录成功');

          _this3.loadingBtn = false;
        }).catch(function () {
          _this3.loadingBtn = false;
        });
      } else {
        this.editPram.pid = Array.isArray(this.editPram.pid) ? this.editPram.pid[0] : this.editPram.pid;
        this.loadingBtn = true;
        categoryApi.updateCategroy(this.editPram).then(function (data) {
          _this3.$emit('hideEditDialog');

          _this3.$message.success('更新目录成功');

          _this3.loadingBtn = false;
        }).catch(function () {
          _this3.loadingBtn = false;
        });
      }
    }
  }
};
exports.default = _default;